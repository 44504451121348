import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import useSSR from 'use-ssr'
import usePortal from '../../../hooks/usePortal'
import confetti, { style } from '../../molecules/Confetti/utils'

const Confetti = ({ fire, id = 'modal-root' }) => {
	const { isBrowser } = useSSR()
	if (isBrowser) {
		const target = usePortal(id)
		const ref = useRef()

		useEffect(() => {
			// setTimeout(() => {
			if (ref.current != null && fire) {
				confetti(ref.current)
			}
			// }, 1000)
		}, [ref.current, fire])

		return createPortal(<div id="confetti-burst" style={style} ref={ref} />, target)
	}

	return null
}

export default Confetti
