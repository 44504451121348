import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Spinner, VStack, Heading, Text } from '@chakra-ui/react'
import { useEventContext } from '../../../contexts/EventProvider'
import { logPurchasedEvent } from '@vidday/utils/dist/trackingEventActions'
import { Helmet } from 'react-helmet'
import CheckExMark from '../../atoms/CheckExMark'
import Confetti from '../../molecules/Confetti'
import { useAuthContext } from '../../../contexts/AuthProvider'
import Card from '../../atoms/Card'
import { isBrowser } from 'react-device-detect'

const Paid = () => {
	/** Retrieve current event global state */
	const {
		event: { status, uuid, occasion },
	} = useEventContext()
	/** Retrieve the history hook */
	const navigate = useNavigate()
	const location = useLocation()
	const [title, setTitle] = useState('')
	const { id, email, profile } = useAuthContext()

	useEffect(() => {
		// only do this in browser (no ssr)
		if (isBrowser) {
			// Extract values from search params
			const search = new URLSearchParams(location.search)
			// check if user is using PWA
			const mqStandAlone = '(display-mode: standalone)'
			const appInstalled = navigator.standalone || window.matchMedia(mqStandAlone).matches ? true : false

			if (search.has('uuid') && search.has('cartId')) {
				// console.log('***************DETECTED purchase tracking in search params, consuming it')
				// Log purchase
				try {
					logPurchasedEvent({
						checkoutSessionId: search.get('session_id') || null,
						uuid: search.get('uuid') || null,
						occasion: search.get('occasion') || null,
						product: search.get('product') || null,
						coupon: search.get('coupon') || null,
						amount: (search.get('amount') / 100).toFixed(2) || null,
						republish: search.get('republish') || null,
						currency: search.get('currency') || 'USD',
						cartId: search.get('cartId') || null,
						eventId: search.get('eventId') || null,
						userId: id || null,
						appInstalled: appInstalled,
						userModel: {
							id: search.get('userId') || null,
							email,
							address: profile?.location || null,
						},
					})
				} catch (error) {
					console.warn('Failed to log purchase event : ', error)
				}

				// Clear search params from window location
				navigate(location.pathname, { state: { isActive: true } })
				// history.replace({ pathname: `${location.pathname}`, search: '', state: { isActive: true } })
			}
		}
	}, [])

	// Escape route - A fallback should the socket fail reload the event/page
	useEffect(() => {
		// if event is already in publishing, just wait a timeout before redirecting
		if (status === 'publishing') {
			setTitle("VidDay - Payment Successful! We'll begin publishing.")
			setTimeout(() => {
				navigate(`/event/${uuid}`, { state: { isActive: true } })

				// history.replace({ pathname: `/${uuid}`, search: '', state: { isActive: true } })
			}, 3000) // enough time to let confetti fall a bit
		} else {
			setTitle('VidDay - finalizing video...')
		}
	}, [status])

	const Success = () => {
		return (
			<VStack alignItems="center" justifyContent="center" w="full" minH="300px">
				{occasion != 'memorial' && <Confetti fire={true} />}
				<CheckExMark render={true} check={true} scale={0.6} />
				<Heading color="gray.900" fontSize="26px">
					Your video is finalizing...
				</Heading>
			</VStack>
		)
	}
	const Waiting = () => {
		return (
			<VStack alignItems="center" justifyContent="center" w="full" minH="300px">
				<Spinner thickness="6px" speed="0.45s" emptyColor="gray.200" color="link" size="lg" />
				<Heading color="gray.900" fontSize="26px">
					Finalizing your video...
				</Heading>
			</VStack>
		)
	}

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="robots" content="noindex nofollow noarchive" />
			</Helmet>
			{location.search === '' && (
				<Container py="2rem">
					<Card p="2rem" width="full">
						{status == 'publishing' && <Success />}
						{status == 'active' && <Waiting />}
					</Card>
				</Container>
			)}
		</>
	)
}

export default Paid
