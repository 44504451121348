/* ******************************
 * LIBRARIES
 ***************************** */
export const config = {
	angle: 90,
	spread: 65,
	startVelocity: 70,
	elementCount: 130,
	decay: 0.91,
	fade: 500,
}

export const defaultColors = ['#FF1C63', '#FFAF2F', '#04D898', '#41BEFF', '#5C50FB']

export const style = {
	position: 'fixed',
	bottom: 0,
	zIndex: 9999,
	left: '50%',
}

function getRandomInt(max) {
	return Math.floor(Math.random() * Math.floor(max))
}

function createElements(root, elementCount, colors) {
	return Array.from({ length: elementCount }).map((_, index) => {
		const element = document.createElement('div')
		const color = colors[index % colors.length]
		element.style['background-color'] = color // eslint-disable-line space-infix-ops
		if (index % getRandomInt(5) == 0) {
			// Lets make some circles
			element.style.width = '10px'
			element.style.height = '10px'
			element.style.borderRadius = '50%'
		} else {
			element.style.width = '10px'
			element.style.height = '15px'
		}
		element.style.position = 'absolute'
		root.appendChild(element)
		return element
	})
}

function randomPhysics(angle, spread, startVelocity, random) {
	const radAngle = angle * (Math.PI / 180)
	const radSpread = spread * (Math.PI / 180)
	return {
		x: 0,
		y: 0,
		wobble: random() * 10,
		velocity: startVelocity * 0.5 + random() * startVelocity,
		angle2D: -radAngle + (0.5 * radSpread - random() * radSpread),
		angle3D: -(Math.PI / 4) + random() * (Math.PI / 2),
		tiltAngle: random() * Math.PI,
	}
}

function updateFetti(fetti, progress, decay) {
	/* eslint-disable no-param-reassign */
	fetti.physics.x += Math.cos(fetti.physics.angle2D) * fetti.physics.velocity
	fetti.physics.y += Math.sin(fetti.physics.angle2D) * fetti.physics.velocity
	fetti.physics.z += Math.sin(fetti.physics.angle3D) * fetti.physics.velocity
	fetti.physics.wobble += 0.1
	fetti.physics.velocity *= decay
	fetti.physics.y += 4
	fetti.physics.tiltAngle += 0.15

	const { x, y, tiltAngle, wobble } = fetti.physics
	const wobbleX = x + 12 * Math.cos(wobble)
	const wobbleY = y + 12 * Math.sin(wobble)
	const transform = `translate3d(${wobbleX}px, ${wobbleY}px, 0) rotate3d(1, 1, 1, ${tiltAngle}rad)`

	fetti.element.style.transform = transform
	fetti.element.style.opacity = 1 - progress

	/* eslint-enable */
}

function animate(root, fettis, decay, fade) {
	const totalTicks = fade
	let tick = 0

	function update() {
		fettis.forEach((fetti) => updateFetti(fetti, tick / totalTicks / (Math.PI / 1.25), decay))

		tick += 1
		if (tick < totalTicks) {
			requestAnimationFrame(update)
		} else {
			fettis.forEach((fetti) => {
				if (fetti.element.parentNode === root) {
					return root.removeChild(fetti.element)
				}
			})
		}
	}

	requestAnimationFrame(update)
}

export default function confetti(root, c = config) {
	const elements = createElements(root, c.elementCount, defaultColors)
	const fettis = elements.map((element) => ({
		element,
		physics: randomPhysics(c.angle, c.spread, c.startVelocity, Math.random),
	}))

	return animate(root, fettis, c.decay, c.fade)
}
